<template>
  <Popup v-model="showPicker" position="bottom">
      <Picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </Popup>
</template>

<script>
import { Picker,  Popup} from 'vant';
export default {
  components:{Picker,  Popup},
  props:["columns","obj"],
  data(){
    return {
       showPicker: false
    }
  },
  watch:{},
  computed:{},
  methods:{
    onConfirm(value) {
      this.$emit('pickerBack', value)
      this.obj.additionalName = value
      this.showPicker = false;
    },
  },
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper{}
</style>