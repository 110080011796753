<template>
  <div class="scre-wrap">
    <div class="submit-btn-box border-b">
        <div class="submit-btn1" @click="preview(0)">预<div style="width:20px;height:10px"></div>览</div>
        <div class="submit-btn1" @click="submit(0,'暂存')">暂<div style="width:20px;height:10px"></div> 存</div>
        <div class="submit-btn1" @click="submit(1, '提交')">提<div style="width:20px;height:10px"></div> 交</div>
        <div class="submit-btn1" v-show="authority=='true'" @click="tsShow=true">发<div style="width:20px;height:10px"></div> 布</div>
    </div>
    <!-- <div class="submit-btn-box border-b" v-if="details.state==2&&authority=='true'">
        <div class="submit-btn1" @click="acceptancePutState(1, '撤回')">撤<div style="width:20px;height:10px"></div>回</div>
    </div> -->
    <!-- <div class="mt-0.5 px-2.5 py-4 bg-white" v-if="JSON.stringify(details.building)!='{}'&&details.specials[0].showDeliveryStatus==1">
        <div class="content-title text-sm">交付状态</div>
        <div  class="text-sm mt-2" v-for="(item, key,index) in details.building" :key="index">
            {{key==1?'毛坯交付':'精装交付'}}：{{item}}
        </div>
    </div> -->
    <!-- 建筑总体得分 -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        
        @click="selDetail(1000)"
      >
        <div class="font-bold flex-1 text-sm">{{ details.specials[0].specialName }}总分</div>
        <div v-if="!details.totalScore" class="pr-1 text-xs text-gray-300">完成评价自动统计总分</div>
        <div v-if="details.totalScore" class="pr-1">
          <span class="score-text" style="color:red;font-size:1.2rem">{{ details.totalScore }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="activeObj['1000'] == true ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <!-- 详细评分 -->
      <div
        v-show="activeObj['1000'] == true"
        class="my-1.5 px-2.5 py-3.5 bg-white leading-none"
      >

        <div class="mb-10">
          <div class="content-title text-sm">项目总览图片</div>
          <div class="flex flex-wrap mt-3.5">
            <com-upload
              key="overview"
              :file-list.sync="details.imgUrl"
            />
          </div>
        </div>
      </div>
    </template>


    <!-- 技术标准扣分项（原减分项） -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        style="height: 57px"
        @click="selDetail(1009)"
      >
        <!-- <div class="font-bold flex-1 text-sm">{{ numberList[details.list?details.list.length:0] }}、技术规定项</div> -->
        <div class="font-bold flex-1 text-sm">一、技术标准扣分项</div>
        <div v-show="!details.reduceTotalScore" class="pr-1 text-xs text-gray-300">完成评价自动统计总分</div>
        <div v-show="details.reduceTotalScore" class="pr-1">
          <span class="score-text">{{ details.reduceTotalScore>0?'-':'' }}{{ details.reduceTotalScore }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="activeObj['1009'] == true ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <div v-show="activeObj['1009'] == true" class="box">
         <div class="pb-2 pt-2 px-2.5" style="border-bottom:1px solid rgba(0, 0, 0, 0.05)">
            <div class="content-title text-sm">概述</div>
            <div v-for="(v, i) in details.additionsList.type3.evaluation" :key="i+'evaluation'" class="flex items-center mt-1 py-1">
              <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                <textarea
                  v-model="details.additionsList.type3.evaluation[i]"
                  placeholder="请输入概述内容"
                  class="flex-1 h-10 text-sm bg-gray-100"
                  style="height: 60px"
                />
              </div>
              
              <div class="ml-4">
                <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(details.additionsList.type3,i)">
                <img v-show="details.additionsList.type3.evaluation.length==i+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="details.additionsList.type3.evaluation.push('')">
              </div>
              
            </div>
            <div class="flex items-center justify-end mt-4 mb-4" v-show="details.additionsList.type3.evaluation.length==0" @click="details.additionsList.type3.evaluation.push('')">
              <img :src="require('assets/project/add.png')" class="icon-xs">
            </div>
            <div class="pr-1 text-xs text-gray-300 mt-1" style="color:#999">本项目违反技术规定【严控】*条，【红条】*条，【黑条】*条。或无违反条款。</div>
         </div>
        <template v-for="(item, index) in details.additionsList.type2">
          <div :key="index">
            <!-- <van-checkbox icon-size="18" style="margin-left: 0.625rem;" v-model="item.negative" shape="square">反面案例</van-checkbox> -->
            <div class="box-item flex items-center mt-1" @click="showPicker2Click(index)">
              <div v-show="!item.additionalName" class="txt" style="color: rgb(168 170 172)">请先择减分项</div>
              <div v-show="item.additionalName" class="txt">{{ item.additionalName }}</div>
              <van-icon style="margin-right:5px" size="14" color="#aaa" name="arrow" />
            </div>
            <div class="box-item flex items-center py-3.5 border-b" style="padding:0 10px">
              <input
                v-model="item.totalScore"
                class="flex-1 text-sm"
                type="text"
                :style="item.totalScore==3||item.totalScore==10?'color:red;font-weight: bold':''"
                placeholder="请输入分数"
                @input="Restricted3(item)"
              >
              <span class="text-sm text-gray-600">分</span>
            </div>
           
            <div v-for="(im, ix) in item.evaluation" :key="ix" style="padding: 0px 10px;" class="flex items-center mt-2 py-1">
              <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                <textarea
                  v-model="item.evaluation[ix]"
                  placeholder="请输入内容"
                  class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                  style="height: 60px"
                />
              </div>
              <div class="ml-4">
                <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(item,ix)">
                <img v-show="item.evaluation.length==ix+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="item.evaluation.push('')">
              </div>
            </div>
            <div v-if="item.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" style="padding: 0px 10px;" @click="item.evaluation.push('')">
                <img :src="require('assets/project/add.png')" class="icon-xs">
            </div>
            <div class="flex flex-wrap mt-4" style="padding-left: 10px;padding-right: 10px;">
              <com-upload
                key="lightspot"
                :file-list.sync="item.imgUrl"
              />
            </div>
            <div class="box-btn" @click="type2DelClick(index)">删除减分项</div>
          </div>
        </template>
        <div style="color:#666666;border-color:#666;margin-bottom:10px" class="box-btn" @click="type2AddClick()">添加新的减分项</div>
      </div>
    </template>

    <!-- 空间动线得分 -->

    <template v-for="(item, index) in details.list">
      <div :key="index" class="">
        <div
          class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
          style="height: 57px"
          @click="selDetail(index)"
        >
          <div class="font-bold flex-1 text-sm">{{ numberList[index+1] }}、{{ item.specialName }}得分(
            <template v-if="item.type0.totalScore!==null">
              <!-- <span class="text-bule" v-show="item.type0.totalScore>=0">{{item.score}}</span> -->
              <span >{{ item.score }}分</span>
              <!-- <span v-show="item.type0.totalScore>=0">{{ item.score }}分</span> -->
            </template>
            )</div>
          <div v-if="!item.type0||!item.type0.totalScore" class="pr-1 text-xs text-gray-300">完成评价自动统计总分</div>
          <div v-if="item.type0&&item.type0.totalScore" class="pr-1">
            <span class="score-text">{{ item.type0.totalScore }}</span>
            <span class="text-xs ml-1">分</span>
          </div>
          <van-icon
            :name="activeObj[index] == true ? 'arrow-up' : 'arrow-down'"
            size="14"
            color="#aaa"
          />
        </div>
        <!-- 详细评分 -->
        <div
          v-show="activeObj[index] == true"
          class="my-1.5 py-3.5 bg-white leading-none"
        >
          <div class="mb-2 px-2.5">
            <div class="content-title text-sm">起评分</div>
            <div class="flex items-center py-3.5 border-b">
              <input
                v-model="item.type0.initScore"
                class="flex-1 text-sm"
                type="text"
                placeholder="请输入起评分"
                @input="Restricted1(item.type0.initScore, item)"
              >
              <span class="text-sm text-gray-600">分</span>
            </div>
          </div>
          <div class="pr-1 text-xs px-2.5 text-gray-300 mb-4" style="color:#999">{{ item.scoreCase }}</div>
          <div class="mb-10 px-2.5">
            <div class="content-title text-sm">总体评价</div>
            <div v-for="(v, i) in item.type0.evaluation" :key="i+'evaluation'" class="flex items-center mt-1 py-1">
              <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                <textarea
                  v-model="item.type0.evaluation[i]"
                  placeholder="请输入总体评价内容"
                  class="flex-1 h-10 text-sm bg-gray-100"
                  style="height: 60px"
                />
              </div>
              
              <div class="ml-4">
                <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(item.type0,i)">
                <img v-show="item.type0.evaluation.length==i+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="item.type0.evaluation.push('')">
              </div>
            </div>
            <!-- <div v-for="(v, i) in item.type0.evaluation" :key="i+'evaluation'" class="flex items-center mt-1 py-3.5 border-b">
              <input v-model="item.type0.evaluation[i]" type="text" class="flex-1 text-sm" placeholder="请输入总体评价内容">
              <img :src="require('assets/project/minus.png')" style="margin-left:10px" alt="" class="icon-xs" @click="delEvaluation(item.type0,i)">
            </div>
            <div class="pr-1 text-xs px-2.5 text-gray-300 mb-4 mt-2" style="color:#999">{{ item.evaluateCase }}</div> -->
            <div class="flex items-center justify-end mt-4 mb-4" v-show="item.type0.evaluation.length==0" @click="item.type0.evaluation.push('')">
              <img :src="require('assets/project/add.png')" class="icon-xs">
            </div>
            <div v-if="item.evaluateCase" class="pr-1 text-xs px-2.5 text-gray-300 mt-1" style="color:#999">{{ item.evaluateCase }}</div>
            <div class="mt-4">
              <div class="content-title text-sm">总体评价图片</div>
              <div class="flex flex-wrap mt-3.5">
                <com-upload
                  key="overview"
                  :file-list.sync="item.type0.imgUrl"
                />
              </div>
            </div>

            <!-- 分项问题 -->
            <template v-if="item.dataType==3">
             <div >
                <div class="mt-5 text-sm font-bold text-red-600">问题点</div>
              </div>
              <template v-for="(v, i) in item.type2">
                <div :key="i" class="characteristic border-b" style="margin-left:0;margin-right:0;margin-top:0">
                  <van-checkbox class="mt-2" icon-size="18" v-model="v.negative" shape="square">反面案例</van-checkbox>
                  <div class="box-item flex items-center mt-1" v-if="item.questionValue.length>0" @click="showPickerClick(('id'+index+'-'+i))">
                    
                    <div v-show="!v.additionalName" class="txt" style="color: rgb(168 170 172);margin-left:0">请先择问题项</div>
                    <div v-show="v.additionalName" class="txt" style="margin-left:0">{{ v.additionalName }}</div>
                    <van-icon style="margin-right:5px" size="14" color="#aaa" name="arrow" />
                  </div>
                  <picker :ref="'id'+index+'-'+i" :columns="item.questionValue" :obj="v" />
                  <div class="flex items-center py-3.5 border-b">
                    <input
                      v-model="v.totalScore"
                      class="flex-1 text-sm"
                      type="text"
                      placeholder="请输入分数"
                      @input="Restricted2(v)"
                    >
                    <span class="text-sm text-gray-600">分</span>
                  </div>
                  <div v-for="(m, j) in v.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-1">
                    <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                      <textarea
                        v-model="v.evaluation[j]"
                        placeholder="请输入问题点内容"
                        class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                        style="height: 60px"
                      />
                    </div>
                    <div class="ml-4">
                      <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(v,j)">
                      <img v-show="v.evaluation.length==j+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="v.evaluation.push('')">
                    </div>
                  </div>

                  <div v-if="v.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" @click="v.evaluation.push('')">
                    <img :src="require('assets/project/add.png')" class="icon-xs">
                  </div>
                  <div class="">
                    <div class="flex flex-wrap mt-3.5">
                      <com-upload
                        key="overview"
                        :file-list.sync="v.imgUrl"
                      />
                    </div>
                  </div>
                  <div class="box-btn" style="width: 100%;margin-left: 0px;" @click="delClick(item.type2,i)">删除问题点</div>
                </div>
              </template>
              <div class="characteristic" style="padding: 5px 0px;">
                  <div
                    class="box-btn"
                    style="width: 100%;margin-left: 0px;color:#666666;border-color:#666;margin-bottom:10px"
                    @click="addClick(item.type2, 2,item)"
                  >新增问题点</div>
              </div>
            <!-- 分项问题 -->
            </template>
          </div>

          <div v-for="(im,ix) in item.children" :key="ix" class="">
            <Collapse v-model="activeNames" accordion>
              <CollapseItem :name="index+'-'+ix">
                <template #title>
                  <div class="flex mt-0.5" >
                    <div style="margin-top:0" class="flex-1 mt-3.5 text-sm text-gray-600">{{ ix+1 }}、{{ im.specialName }}</div>
                    <div class="text-sm text-gray-600 mr-2" v-if="im.type0.totalScore!=null">{{im.type0.totalScore}}分</div>
                    <div class="text-sm text-gray-600 mr-2" v-else>无</div>
                  </div>
                </template>
                <div style="padding: 0rem 1rem .7rem;background-color:#fff">
                  <div class="content-title text-sm mt-2" style="color: #333;">概述</div>
                  <!-- <div class="flex mb-2 mt-2 px-2.5 py-3.5 text-sm bg-gray-100 rounded-md">
                    <textarea
                      v-model="im.type0.evaluation[0]"
                      placeholder="请输入评价"
                      class="flex-1 h-24 text-sm bg-gray-100"
                    />
                  </div> -->
                  <div v-for="(m, j) in im.type0.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-1">
                    <div class="textarea-red flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                      <textarea
                        v-model="im.type0.evaluation[j]"
                        placeholder="请填写概述"
                        class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                        style="height: 60px;placeholder-color: red"
                      />
                    </div>
                    <div class="ml-4">
                      <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(im.type0,j)">
                      <img v-show="im.type0.evaluation.length==j+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="im.type0.evaluation.push('')">
                    </div>
                  </div>
                  <div v-if="im.type0.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" @click="im.type0.evaluation.push('')">
                    <img :src="require('assets/project/add.png')" class="icon-xs">
                  </div>
                  <div class="pr-1 text-xs text-gray-300 mb-4 mt-2" style="color:#999">{{ im.evaluateCase }}</div>
                  
                  <div class="mt-3 text-sm font-bold text-green-600">亮点</div>
                </div>
                <template v-for="(v, i) in im.type1">
                  <div :key="i+'type1'" class="characteristic">
                    <div class="flex items-center py-3.5 border-b">
                      <input
                        v-model="v.totalScore"
                        class="flex-1 text-sm"
                        type="text"
                        placeholder="请输入分数"
                        @input="Restricted2(v, item)"
                      >
                      <span class="text-sm text-gray-600">分</span>
                    </div>
                    <div v-for="(m, j) in v.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-1">
                      <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                        <textarea
                          v-model="v.evaluation[j]"
                          placeholder="请输入亮点内容"
                          class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                          style="height: 60px"
                        />
                      </div>
                      <div class="ml-4">
                        <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(v,j)">
                        <img v-show="v.evaluation.length==j+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="v.evaluation.push('')">
                      </div>
                    </div>
                    <!-- <div v-for="(m, j) in v.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-3.5 border-b">
                      <input v-model="v.evaluation[j]" type="text" class="flex-1 text-sm" placeholder="请输入亮点内容">
                      <img :src="require('assets/project/minus.png')" style="margin-left:10px" alt="" class="icon-xs" @click="delEvaluation(v,j)">
                    </div> -->
                    <div v-if="v.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" @click="v.evaluation.push('')">
                      <img :src="require('assets/project/add.png')" class="icon-xs">
                    </div>
                    <div class="">
                      <div class="flex flex-wrap mt-3.5">
                        <com-upload
                          key="overview"
                          :file-list.sync="v.imgUrl"
                        />
                      </div>
                    </div>
                    <div class="box-btn" style="width: 100%;margin-left: 0px;border-color:rgba(5, 150, 105);color:rgba(5, 150, 105)" @click="delClick(im.type1,i)">删除亮点</div>

                  </div>

                </template>
                <div class="characteristic" style="padding: 5px 10px;">
                  <div
                    class="box-btn"
                    style="width: 100%;margin-left: 0px;color:#666666;border-color:#666;margin-bottom:10px"
                    @click="addClick(im.type1, 1,im)"
                  >新增亮点</div>
                </div>

                <div style="padding: .1rem 1rem .7rem;background-color:#fff">
                  <div class="mt-3 text-sm font-bold text-red-600">问题点</div>
                </div>
                <template v-for="(v, i) in im.type2">
                  <div :key="i" class="characteristic">
                    <van-checkbox class="mt-2" icon-size="18" v-model="v.negative" shape="square">反面案例</van-checkbox>
                    <div class="flex items-center py-3.5 border-b">
                      <input
                        v-model="v.totalScore"
                        class="flex-1 text-sm"
                        type="text"
                        placeholder="请输入分数"
                        @input="Restricted2(v, item)"
                      >
                      <span class="text-sm text-gray-600">分</span>
                    </div>
                    
                    <div v-for="(m, j) in v.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-1">
                      <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                        <textarea
                          v-model="v.evaluation[j]"
                          placeholder="请输入问题点内容"
                          class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                          style="height: 60px"
                        />
                      </div>
                      <div class="ml-4">
                        <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(v,j)">
                        <img v-show="v.evaluation.length==j+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="v.evaluation.push('')">
                      </div>
                    </div>
                    <!-- <div v-for="(m, j) in v.evaluation" :key="j+'evaluation'" class="flex items-center mt-1 py-3.5 border-b">
                      <input v-model="v.evaluation[j]" type="text" class="flex-1 text-sm" placeholder="请输入问题点内容">
                      <img :src="require('assets/project/minus.png')" style="margin-left:10px" alt="" class="icon-xs" @click="delEvaluation(v,j)">
                    </div>-->
                    <div v-if="v.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" @click="v.evaluation.push('')">
                      <img :src="require('assets/project/add.png')" class="icon-xs">
                    </div>
                    <div class="">
                      <div class="flex flex-wrap mt-3.5">
                        <com-upload
                          key="overview"
                          :file-list.sync="v.imgUrl"
                        />
                      </div>
                    </div>
                    <div class="box-btn" style="width: 100%;margin-left: 0px;" @click="delClick(im.type2,i)">删除问题点</div>

                  </div>
                </template>
                <div class="characteristic" style="padding: 5px 10px;">
                  <div
                    class="box-btn"
                    style="width: 100%;margin-left: 0px;color:#666666;border-color:#666;margin-bottom:10px"
                    @click="addClick(im.type2, 2,im)"
                  >新增问题点</div>
                </div>

              </CollapseItem>

            </Collapse>
          </div>
        </div>

        
      </div>
    </template>


     

    <!-- 附加分项 -->
    <template>
      <div
        class="flex items-center mt-0.5 px-2.5 py-4 bg-white"
        style="height: 57px"
        @click="selDetail(1008)"
      >
        <div class="font-bold flex-1 text-sm">{{ numberList[details.list?details.list.length+1:0] }}、加分项</div>
        <div v-show="!details.addTotalScore" class="pr-1 text-xs text-gray-300">完成评价自动统计总分</div>
        <div v-show="details.addTotalScore" class="pr-1">
          <span class="score-text">{{ details.addTotalScore }}</span>
          <span class="text-xs ml-1">分</span>
        </div>
        <van-icon
          :name="activeObj['1008'] == true ? 'arrow-up' : 'arrow-down'"
          size="14"
          color="#aaa"
        />
      </div>
      <div v-show="activeObj['1008'] == true" class="box">
        <template v-for="(item, index) in details.additionsList.type1">
          <div :key="index" class="">
            <div class="box-item flex items-center mt-1" @click="showPicker1Click(index)">
              <div v-show="!item.additionalName" class="txt" style="color: rgb(168 170 172)">请先择加分项</div>
              <div v-show="item.additionalName" class="txt">{{ item.additionalName }}</div>
              <van-icon style="margin-right:5px" size="14" color="#aaa" name="arrow" />
            </div>
            <div class="box-item flex items-center py-3.5 border-b" style="padding:0 10px">
              <input
                v-model="item.totalScore"
                class="flex-1 text-sm"
                type="text"
                placeholder="请输入分数"
                @input="Restricted3(item)"
              >
              <span class="text-sm text-gray-600">分</span>
            </div>
            <div v-for="(im, ix) in item.evaluation" :key="ix" style="padding: 0px 10px;" class="flex items-center mt-2 py-1">
              <div class="flex flex-1 px-2.5 py-2 text-sm bg-gray-100 rounded-md">
                <textarea
                  v-model="item.evaluation[ix]"
                  placeholder="请输入内容"
                  class="flex-1 h-10 text-sm bg-gray-100 text-gray-600"
                  style="height: 60px"
                />
              </div>
              <div class="ml-4">
                <img :src="require('assets/project/minus.png')" alt="" class="icon-xs" @click="delEvaluation(item,ix)">
                <img v-show="item.evaluation.length==ix+1" :src="require('assets/project/add.png')" class="icon-xs mt-3" @click="item.evaluation.push('')">
              </div>
            </div>
            <div v-if="item.evaluation.length==0" class="flex items-center justify-end mt-4 mb-4" style="padding: 0px 10px;" @click="item.evaluation.push('')">
                <img :src="require('assets/project/add.png')" class="icon-xs">
            </div>
            <!-- <div style="padding:10px 10px;">
              <div class="flex px-2.5 py-3.5 text-sm bg-gray-100 rounded-md">
                <textarea
                  v-model="item.evaluation"
                  placeholder="请输入内容"
                  class="flex-1 h-24 text-sm bg-gray-100"
                />
              </div>
            </div> -->
            <div class="flex flex-wrap mt-4" style="padding-left: 10px;padding-right: 10px;">
              <com-upload
                key="lightspot"
                :file-list.sync="item.imgUrl"
              />
            </div>
            <div class="box-btn" @click="type1DelClick(index)">删除加分项</div>
          </div>
        </template>
        <div style="color:#666666;border-color:#666;margin-bottom:10px" class="box-btn" @click="type1AddClick()">添加新的加分项</div>
      </div>
    </template>
   

    
    <picker ref="picker1" :columns="['其他','临时样板房前厅','架空层','看房通道','实体公区']" :obj="details.additionsList.type1[showPicker1Index]" />
    <picker ref="picker2" :columns="['技术规定','技术标准','反面案例','其他补充规定','其他']" :obj="details.additionsList.type2[showPicker2Index]" />
    
    <div id="putAway">
      <div class="putAway flex" @click="putAwayClick" :style="{right: putAwayShow?'.3rem':''}"></div>
    </div>
    <!-- 发布提示框 -->

        <van-dialog v-model="tsShow" title="提示" confirmButtonText="发布" @confirm="submit(2)" show-cancel-button>
          <van-radio-group v-model="qualified">
            <div class="flex items-center justify-center py-10">
              <van-radio name="1">合格</van-radio>
              <div style="width: 40px;height:20px"></div>
              <van-radio name="0">不合格</van-radio>
            </div>
          </van-radio-group>
        </van-dialog>
        <!-- 发布提示框 -->
  </div>
</template>
<script>

import { Icon, Uploader, Popup, checkbox, checkboxGroup, Dialog, Radio, RadioGroup, Collapse, CollapseItem } from 'vant'
import comUpload from 'components/comUpload'
import picker from './picker'
import { acceptance, acceptancePut, shareLinks } from '../api'
import Clipboard from 'clipboard'
export default {
  naem:'ScoreEdit',
  components:{
    [Icon.name]:Icon,
    [Uploader.name]:Uploader,
    [checkbox.name]:checkbox,
    [checkboxGroup.name]:checkboxGroup,
    [Dialog.Component.name]:Dialog.Component,
    [RadioGroup.name]:RadioGroup,
    [Radio.name]:Radio,
    comUpload,
    Collapse,
    CollapseItem,
    picker,
    Popup,
  },
  props:['details'],
  data() {
    return {
      show:false,
      tsShow:false,
      qualified: '1', //'合格：0->否; 1->是
      active:null,
      activeObj: {},
      // 概览图
      overview_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      // 亮点图
      lightspot_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      // 问题点图
      threat_img_list:[{ url:'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      numberList:{
        0:'一',
        1:'二',
        2:'三',
        3:'四',
        4:'五',
        5:'六',
        6:'七',
        7:'八',
        8:'九',
        9:'十',
        10:'十一',
        11:'十二',
        12:'十三',
        13:'十四',
        14:'十五',
        15:'十六',
        16:'十七',
        17:'十八',
        18:'十九',
        19:'二十',
        20:'二十一',
        21:'二十二',
        22:'二十三',
        23:'二十四',
        24:'二十五',
        25:'二十六',
        26:'二十七',
        27:'二十八',
        28:'二十九',
        29:'三十'
      },
      activeNames:[],
      showPicker1:false,
      showPicker1Index:0,
      showPicker2:false,
      showPicker2Index:0,
      previewUrl:'',
      timer: null,
      timer1: null,
      timer2: null,
      putAwayShow: true,
      trigger: 0,    //表单编辑触发次数
      authority: 'false'  //权限
    }
  },
  watch:{
    'details.list':{
      handler(val) {
        var totalScore = 0
        var topicScore = 0  //题目分数
        for (var item of val) {
          var score = 0
          //主项亮点与问题点算分
          if (item.dataType==3) {
            
            for (var im of item.type2) {
              score = this.NumberSub(score, im.totalScore)
            }
          }

          //子项算分
          for (var im of item.children) {
            var subitemScore = im.type0.initScore
            for (var v of im.type1) {
              score = this.NumberAdd(score, v.totalScore)
              subitemScore = this.NumberAdd(subitemScore, v.totalScore)
            }
            for (var v of im.type2) {
              score = this.NumberSub(score, v.totalScore)
              subitemScore = this.NumberSub(subitemScore, v.totalScore)
            }
            im.type0.totalScore = subitemScore
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score) 
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)

          //题目计算总分
          if (item.type0.totalScore!=0) {
            topicScore += item.score
          }
        }
        //题目计算总分
        totalScore = this.NumberMul(this.NumberDiv(totalScore, topicScore, 3), 100).toFixed(1)
        this.details.totalScore = this.NumberSub(this.NumberAdd(totalScore,this.details.addTotalScore),this.details.reduceTotalScore)
        this.$forceUpdate()
        this.realTimeSaving()
      },
      deep:true
    },
    'details.additionsList.type1':{
      handler(arr) {
        var totalScore = 0
        for (var item of arr) {
          totalScore = this.NumberAdd(totalScore, item.totalScore)
        }
        this.details.addTotalScore = totalScore
        for (var item of this.details.list) {
          var score = 0
          for (var im of item.children) {
            score = this.NumberSub(this.NumberAdd(score, im.type1.totalScore),im.type2.totalScore)
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score)
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)
        }
        this.details.totalScore = totalScore
        this.realTimeSaving()
      },
      deep:true,
      immediate: true
    },
    'details.additionsList.type2':{
      handler(arr) {
        var totalScore = 0
        for (var item of arr) {
          totalScore = this.NumberAdd(totalScore, item.totalScore)
        }
        this.details.reduceTotalScore = totalScore
        totalScore = -totalScore
        for (var item of this.details.list) {
          var score = 0
          for (var im of item.children) {
            score = this.NumberSub(this.NumberAdd(score, im.type1.totalScore),im.type2.totalScore)
          }
          item.type0.totalScore = this.NumberAdd(item.type0.initScore,score)
          totalScore = this.NumberAdd(totalScore, item.type0.totalScore)
        }
        this.details.totalScore = totalScore
        this.realTimeSaving()
      },
      deep:true,
      immediate: true
    }
  },
  created() {
    this.authority = localStorage.getItem('authority')
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods:{
    //收起
    putAwayClick() {
      this.additionalName = []
      for (var key in this.activeObj) {
        this.activeObj[key] = false
      }
      this.$forceUpdate()
    },
    handleScroll () {
      this.putAwayShow=false
      clearTimeout(this.timer2)
      this.timer2 = setTimeout(()=>{
        this.putAwayShow=true
      },1000)
    },
    //分享亮点问题点选择器
    showPickerClick(id) {
      console.log(this.$refs[id]);
      this.$refs[id][0].showPicker = true
    },
    //实时保存
    realTimeSaving () {
      clearTimeout(this.timer)
      clearTimeout(this.timer1)
      this.timer1 = setTimeout(()=>{
        this.trigger++
      },1500)
      this.timer = setTimeout(()=>{
        var form = {
          additions: []
        }
        var data = JSON.parse(JSON.stringify(this.details))
        var contents = []
        for (var item of data.list) {
          item.type0.imgUrl = item.type0.imgUrl ? item.type0.imgUrl.join() : ''
          item.type0.evaluation = item.type0.evaluation.join('%s%')
          contents.push(item.type0)
          // 分项问题点
          if (item.dataType==3) {
            for (var im of item.type2) {
              im.imgUrl = item.imgUrl ? im.imgUrl.join() : ''
              im.evaluation = im.evaluation.join('%s%')
              contents.push(im)
            } 
          }
          // 分项问题点
          for (var im of item.children) {
            im.type0.imgUrl = im.type0.imgUrl ? im.type0.imgUrl.join() : ''
            im.type0.evaluation = im.type0.evaluation.join('%s%')
            
            contents.push(im.type0)
            for (var v of im.type1) {
              v.imgUrl = v.imgUrl ? v.imgUrl.join() : ''
              v.evaluation = v.evaluation.join('%s%')
              contents.push(v)
            }
            for (var v of im.type2) {
              v.imgUrl = v.imgUrl ? v.imgUrl.join() : ''
              v.evaluation = v.evaluation.join('%s%')
              contents.push(v)
            }
          }
        }
        form.contents = contents
        for (var item of data.additionsList.type1) {
          item.imgUrl = item.imgUrl.join()
          item.evaluation = item.evaluation.join('%s%')
          form.additions.push(item)
        }
        for (var item of data.additionsList.type2) {
          item.imgUrl = item.imgUrl.join()
          item.evaluation = item.evaluation.join('%s%')
          form.additions.push(item)
        }
        data.additionsList.type3.evaluation = data.additionsList.type3.evaluation.join('%s%')
        data.additionsList.type3.imgUrl = data.additionsList.type3.imgUrl.join()
        form.additions.push(data.additionsList.type3)
        form.evaluation = data.evaluation
        form.imgUrl = data.imgUrl.join()
        form.version = data.version
        var key = data.projectId + '-' + data.specialId + '-' + data.housesId
        localStorage.setItem(key, JSON.stringify(form))
        
        
      }, 5000)
    },
    // 删除总体评价
    delEvaluation(item, i) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除该内容',
      }).then(() => {
          // on confirm
          item.evaluation.splice(i, 1)
        })
      
    },
    // 亮点或者问题点新增
    addClick(im, type, item) {
      im.push({
        additionalName:'',
        evaluation:[],
        imgUrl:[],
        initScore:null,
        isType:type,
        negative: 0,   //问题点
        itemType:0,
        specialId:item.id,
        totalScore:null
      })
    },
    // 删除亮点或者问题点
    delClick(item, index) {
      this.$dialog.confirm({
        title: '提示',
        message: '确认删除该项',
      }).then(() => {
          // on confirm
          item.splice(index, 1)
        })
      
    },
    // 附加分新增
    type1AddClick() {
      this.details.additionsList.type1.push({
        additionalName:'',
        evaluation:[''],
        imgUrl:[],
        initScore:0,
        isType:1,
        negative: 0,
        itemType:1,
        specialId:null,
        totalScore:null
      })
    },
    // 附加分删除
    type1DelClick(index) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认删除',
      }).then(() => {
         this.details.additionsList.type1.splice(index, 1)
      })
      
    },
    // 附加分项弹窗
    showPicker1Click(index) {
      this.$refs.picker1.showPicker = true
      this.showPicker1Index = index
    },
    // 附减分新增
    type2AddClick() {
      this.details.additionsList.type2.push({
        additionalName:'',
        evaluation:[''],
        imgUrl:[],
        initScore:0,
        isType:2,
        negative: 0,
        itemType:1,
        specialId:null,
        totalScore:null
      })
      console.log(this.details.additionsList);
    },
    // 附减分删除
    type2DelClick(index) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认删除',
      }).then(() => {
         this.details.additionsList.type2.splice(index, 1)
      })
     
    },
    // 附减分项弹窗
    showPicker2Click(index) {
      this.$refs.picker2.showPicker = true
      this.showPicker2Index = index
    },
    // 点击预览
    preview(state) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认预览',
      }).then(() => {
          // on confirm
          if (this.details.id) {
            this.acceptancePut(state,(id) => {
              // this.shareLinks(id)
              this.$router.push({path: '/acceptance/list/details/preview',query: {arr: this.$route.query.arr}})
            })
          } else {
            this.acceptance(state,(id) => {
              // this.shareLinks(id)
              this.$router.push({path: '/acceptance/list/details/preview',query: {arr: this.$route.query.arr}})
            })
          }
        })
      
    },
    
    // 提交
    submit(state, text) {
      this.trigger = 0
      if ((state==1||state==2)&&this.details.additionsList.type3.evaluation.join('').replace(RegExp(' ','g'), '')=='') {
        return this.$toast('请先填写技术标准扣分项概述')
      }
      if (text) {
        this.$dialog.confirm({
          title: '提示',
          message: '是否确认' + text,
        }).then(() => {
          if (this.details.id) {
            this.acceptancePut(state)
          } else {
            this.acceptance(state)
          }
        })
      } else {
        if (this.details.id) {
          this.acceptancePut(state)
        } else {
          this.acceptance(state)
        }
      }
    },
    // 新增
    acceptance(state,back) {
      this.$toast.loading({
        message:'',
        forbidClick:true,
        duration:0
      })
      acceptance(this.processingData(state)).then(res => {
        this.details.id = res.data.data.id
        this.details.version = res.data.data.version
        if (back) {
          back(res.data.data.id)
        } else {
           if (state==0) {
            this.$toast.success('暂存成功')
          } else if (state==1){
            this.$toast.success('提交成功')
          } else {
            this.details.state = state
            this.$toast.success({
              message: '发布成功',
              forbidClick: true
            })
            setTimeout(()=>{
              this.$router.go(-1)
            },1500)
          }
          
        }
      }).catch(() => {
        this.$toast.clear()
      }).finally(()=>{
        var data = this.details
        var key = data.projectId + '-' + data.specialId + '-' + data.housesId
        localStorage.removeItem(key)
      })
    },
    
    // 修改
    acceptancePut(state,back) {
      this.processingData()
      this.$toast.loading({
        message:'',
        forbidClick:true,
        duration:0
      })
      acceptancePut(this.details.id, this.processingData(state)).then(res => {
        this.details.version = res.data.data.version
        if (back) {
          back(res.data.data.id)
        } else {
          if (state==0) {
            this.$toast.success('暂存成功')
          } else if (state==1){
            this.$toast.success('提交成功')
          } else {
            this.details.state = state
            this.$toast.success({
              message: '发布成功',
              forbidClick: true
            })
            setTimeout(()=>{
              this.$router.go(-1)
            },1500)
            // this.$emit('back', state)
          }
          
        }
      }).catch(() => {
        this.$toast.clear()
      }).finally(()=>{
        var data = this.details
        var key = data.projectId + '-' + data.specialId + '-' + data.housesId
        localStorage.removeItem(key)
      })
    },
    // 提交时候数据处理
    processingData(state) {
      var form = JSON.parse(localStorage.getItem('form'))
      var contents = []
      var details = JSON.parse(JSON.stringify(this.details))
      for (var item of details.list) {
        item.type0.imgUrl = item.type0.imgUrl ? item.type0.imgUrl.join() : ''
        item.type0.evaluation = item.type0.evaluation.join('%s%')
        contents.push(item.type0)
        // 分项问题点
        if (item.dataType==3) {
          for (var im of item.type2) {
            im.negative = im.negative===true||im.negative==1?1:0
            im.imgUrl = item.imgUrl ? im.imgUrl.join() : ''
            im.evaluation = im.evaluation.join('%s%')
            contents.push(im)
          } 
        }
        // 分项问题点
        for (var im of item.children) {
          im.type0.imgUrl = im.type0.imgUrl ? im.type0.imgUrl.join() : ''
          im.type0.evaluation = im.type0.evaluation.join('%s%')
          
          contents.push(im.type0)
          // im.type1.imgUrl = im.type1.imgUrl?im.type1.imgUrl.join():''
          // im.type2.imgUrl = im.type2.imgUrl?im.type2.imgUrl.join():''
          for (var v of im.type1) {
            v.imgUrl = v.imgUrl ? v.imgUrl.join() : ''
            v.evaluation = v.evaluation.join('%s%')
            contents.push(v)
          }
          for (var v of im.type2) {
            v.negative = v.negative===true||v.negative==1?1:0
            v.imgUrl = v.imgUrl ? v.imgUrl.join() : ''
            v.evaluation = v.evaluation.join('%s%')
            contents.push(v)
          }
        }
      }
      form.childs = contents
      for (var item of details.additionsList.type1) {
        item.imgUrl = item.imgUrl.join()
        item.evaluation = item.evaluation.join('%s%')
      }
      
      for (var item of details.additionsList.type2) {
        item.negative = item.negative===true||item.negative==1?1:0
        // item.negative = item.negative===true||v.negative==1?1:0
        item.imgUrl = item.imgUrl.join()
        item.evaluation = item.evaluation.join('%s%')
      }
      details.additionsList.type3.evaluation = details.additionsList.type3.evaluation.join('%s%')
      details.additionsList.type3.imgUrl = details.additionsList.type3.imgUrl.join()
      form.childs = form.childs.concat(details.additionsList.type1)
      form.childs = form.childs.concat(details.additionsList.type2)
      form.childs.push(details.additionsList.type3)
      form.id = details.id
      form.acceptanceDate = details.acceptanceDate
      form.totalScore = details.totalScore==null?0:details.totalScore
      form.evaluation = details.evaluation
      form.imgUrl = details.imgUrl.join()
      //state 0 暂存  1 发布
      form.state = state
      //版本
      form.version = details.version

      //是否合格发布
      form.qualified = this.qualified
      return form
    },
    selDetail(index) {
      this.activeObj[index] = this.activeObj[index]?false:!this.activeObj[index]
      // console.log(this.activeObj);
      this.$forceUpdate()
      // if (this.active == index) {
      //   this.active = -1
      // } else {
      //   this.active = index
      // }
    },
    Restricted1(values, item) {
      item.type0.initScore = this.plusOrMinus(values)
      this.$forceUpdate()
    },
    Restricted2(im, item) {
      im.totalScore = this.plusOrMinus(im.totalScore)
      this.$forceUpdate()
    },
    Restricted3(item) {
      item.totalScore = this.plusOrMinus(item.totalScore)
    },
    plusOrMinus1(values) {
      let newValue
      values = String(values)
      if (!(/[^0-9.]/g.test(values))) {
        newValue = values.replace('.', '').replace(/[^\-\d.]/g, '').replace(/\-{2,}/g, '-').replace(/\-{2,}/g, '-').replace(/^\./g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[1].length) > 1) {
          newValue = parseInt(parseFloat(newValue) * 100) / 100
        }
        if ((newValue.toString().split('-').length - 1) > 1) {
          newValue = parseFloat(newValue) || ''
        }
        if ((newValue.toString().split('-').length) > 1 && newValue.toString().split('-')[0].length > 0) {
          newValue = parseFloat(newValue) || ''
        }
        if (newValue.toString().length > 1 && (newValue.toString().charAt(0) === '0' || (newValue.toString().length > 2 && newValue.toString().charAt(0) === '-' && newValue.toString().charAt(1) === '0' && newValue.toString().charAt(2) !== '.')) && newValue.toString().indexOf('.') < 1) {
          newValue = parseFloat(newValue) || ''
        }
        // // 判断整数位最多为9位
        if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[0].length) > 9) {
          newValue = `${newValue.toString().substring(0, 9)}.${newValue.toString().split('.')[1]}`;
        } else if (newValue.toString().indexOf('.') < 0 && Number(newValue.toString().split('.')[0].length) > 9) {
          newValue = newValue.toString().substring(0, 9);
        }
      } else {
        newValue = values.replace(/[^0-9]/g, '').replace('-', '')
      }

      return newValue
    },
    plusOrMinus(values) {
      let newValue
      if (!(/[^0-9.-]/g.test(values))) {
        newValue = values.replace(/[^\-\d.]/g, '').replace('-', '').replace(/\b(0+){2,}/g, '0').replace(/\-{2,}/g, '-').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[1].length) > 1) {
          newValue = parseInt(parseFloat(newValue) * 10) / 10
        }
        if ((newValue.toString().split('-').length - 1) > 1) {
          newValue = parseFloat(newValue) || ''
        }
        if ((newValue.toString().split('-').length) > 1 && newValue.toString().split('-')[0].length > 0) {
          newValue = parseFloat(newValue) || ''
        }
        if (newValue.toString().length > 1 && (newValue.toString().charAt(0) === '0' || (newValue.toString().length > 2 && newValue.toString().charAt(0) === '-' && newValue.toString().charAt(1) === '0' && newValue.toString().charAt(2) !== '.')) && newValue.toString().indexOf('.') < 1) {
          newValue = parseFloat(newValue) || ''
        }
        // 判断整数位最多为2位
        if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[0].length) > 2) {
          newValue = newValue.toString().substring(0, 2) + '.' + newValue.toString().split('.')[1]
        } else if (newValue.toString().indexOf('.') < 0 && Number(newValue.toString().split('.')[0].length) > 2) {
          newValue = newValue.toString().substring(0, 2)
        }
      } else {
       
        newValue = values.replace(/[^0-9.-]/g, '')
      }
      return newValue
    }
  },
  destroyed(){
    
    if (this.trigger>1) {
      var se=confirm("是否暂存表单");
      if (se==true) {
        this.submit(0)
      }
    }
    clearTimeout(this.timer)
    clearTimeout(this.timer1)
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<style scoped>
textarea {
  resize: none;
}
.submit-btn-box{
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  left: 0;
  top: 0px;
  height: 50px;
  background-color: #fff;
  z-index: 1000;
}
.submit-btn1 {
  display: flex;
  text-align: center;
  justify-content: center;
  flex:1;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(to bottom, #7fa9ff, #6497ff);
  box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,
    -1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
  border-radius: 5px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  box-sizing: border-box;
  margin: 0 4px;
}
.box {
  width: calc(100% - 20px);
  background: #FFFFFF;
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
}
.box-item{
  width: 100%;
  height: 40px;
  justify-content: space-between;
  background: #FFFFFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.box-item .txt{
  margin-left: 10px;
  width: 340px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.box-btn{
  width: calc(100% - 16px);
  height: 42px;
  margin-left: 8px;
  margin-top: 10px;
  border: 1px dashed #FF3E4C;
  border-radius: 5px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FF3E4C;
}

.scre-wrap>>>.van-collapse-item__content{
  background-color: #f6f6f6;
  padding: 0;
  border-bottom: 5px solid #f6f6f6;
}
.characteristic{
  margin: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.textarea-red{
  background-color: #f1d9d9!important;
}
.textarea-red textarea{
  background-color: #f1d9d9!important;
}
.textarea-red textarea::-webkit-input-placeholder {
  /* color: red; */
}
.icon-xs{
  /* pointer-events:auto; */
  pointer-events:unset!important;
}
.putAway{
    position: fixed;
    right: -150px;
    transition: right .6s;
    bottom: 50px;
    width: 50px;
    height: 50px;
    z-index: 1000;
    border-radius: 50%;
    background-image: url(../assets/home/putAway.png);
    background-size: 100% 100%;
}
.scre-wrap>>>.van-checkbox__label{
    font-size: 0.875rem;
}

</style>
